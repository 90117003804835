body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(0, 225, 255);
  /* background-image: linear-gradient(to bottom right, #003cff,#00ffff); */
  background-image: url('../public/background-image4.svg');
  /* background-size: 200% 200%; */
  /* animation: flow 10s infinite; */
  height: 100%;
  background-attachment: fixed;
	margin: auto;
}

@keyframes flow {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
