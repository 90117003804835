.SplashScreen{
  margin: auto;
  margin-top:2rem;
  display: block;
  justify-content: space-around;
  align-content: center;
}
.SplashScreen h1{
  font-weight: 900;
  font-size: 64px;
  color: white;
  text-shadow: black;
  margin-bottom: 0.3rem;
  margin-top: 8rem;
}
.SplashScreen h4{
  margin-top: 0.5rem;
  font-weight: 900;
  color: white;
  text-shadow: black;
}
.SplashScreen a{
  text-decoration: none;
  z-index: 999;
  font-weight: 900;
  color: rgb(255, 217, 0);
  text-shadow: black;
  transition: color .3s ease;
}

.SplashScreen a:hover{
  color: rgb(255, 174, 0);
}

.SplashScreen-logo {
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .SplashScreen-logo {
    animation: SplashScreen-logo-spin infinite 10s linear;
  }
}
@keyframes SplashScreen-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}