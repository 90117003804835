@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans&family=Krub:wght@300&family=Noto+Sans+Display:wght@300&display=swap");
.App {
	font-family: Krub, sans-serif;
	text-align: center;
	margin: auto;
	align-items: center;
}
.transition-enter {
	opacity: 0;
}
.transition-enter-active{
	opacity: 1;
	transition: all .5s;
}
.transition-exit{
	opacity: 1;
}
.transition-exit-active{
	opacity: 0;
	transition: all .5s;
	transform: scale(4);
}